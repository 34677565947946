<template>
  <v-card class="PlanejamentoAcaoFormCampos__Card--treslinhas">
    <v-container fluid grid-list-md>
      <v-row>
        <v-col id="foco_acao" cols="12" class="pb-0">
          <v-select
            v-model="focoAcao"
            :items="focoAcaoConfiguracao"
            :disabled="somenteLeitura || unicoFocoAcao"
            :label="`${$t('label.foco_acao')} *`"
            item-text="texto"
            item-value="valor"
            :rules="[rules.required]"
            @change="alterarTipo">
          </v-select>
        </v-col>
        <v-col id="planejamento-autocomplete"  class="py-0" cols="12" v-if="exibeFocoHolding || exibeFocoCliente || exibeFocoRegional || focoAcao">
          <v-autocomplete
            id="planejamento-autocomplete-holding"
            class="custom-autocomplete"
            v-if="exibeFocoHolding"
            v-model="acao.holding"
            return-object
            :items="holdings"
            :rules="rules.requiredFunction('holding', 'nomExtensao')"
            :label="`${$tc('label.holding', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nomExtensao"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaFocoAcao"
            :filter="() => true"
            :clearable="true"
            @input="holdingAlterada"
            :placeholder="(somenteLeitura || desabilitaFocoAcao) ? null : $tc('message.digite_para_pesquisar', 1)"
            @click:append="() => triggerSelecao('planejamento-autocomplete-holding')"
            @click.native="(i) => buscaAutocomplete(i, buscaHoldings)"
            :search-input.sync="triggerHolding">
          </v-autocomplete>
          <v-autocomplete
            id="planejamento-autocomplete-cliente"
            class="custom-autocomplete"
            v-else-if="exibeFocoCliente"
            v-model="acao.cliente"
            return-object
            :rules="rules.requiredFunction('cliente', 'codNome')"
            :items="clientes"
            :label="`${$tc('label.foco_acao_cliente', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="codNome"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaFocoAcao"
            :placeholder="(somenteLeitura || desabilitaFocoAcao) ? null : $tc('message.digite_para_pesquisar', 1)"
            :filter="() => true"
            :clearable="true"
            @input="clienteAlterado"
            @click:append="() => triggerSelecao('planejamento-autocomplete-cliente')"
            @click.native="(i) => buscaAutocomplete(i, buscaClientes)"
            :search-input.sync="triggerCliente">
          </v-autocomplete>
          <v-autocomplete
            id="planejamento-autocomplete-regional"
            class="custom-autocomplete"
            v-else-if="exibeFocoRegional"
            v-model="acao.regional"
            return-object
            :rules="rules.requiredFunction('regional', 'id')"
            :items="regionais"
            :label="`${$tc('label.regional', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nome"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaFocoAcao || !acao.divisao"
            :placeholder="(somenteLeitura || desabilitaFocoAcao) ? null : $tc('message.digite_para_pesquisar', 1)"
            :clearable="true"
            @input="regionalAlterada"
            @click:append="() => triggerSelecao('planejamento-autocomplete-regional')"
            @click.native="(i) => buscaAutocomplete(i, buscaRegionais)"
            :search-input.sync="triggerRegional">
          </v-autocomplete>
          <v-autocomplete
            id="planejamento-autocomplete-extensao-cliente"
            class="custom-autocomplete"
            v-else-if="focoAcao"
            v-model="acao.extensaoCliente"
            return-object
            :items="extensoesCliente"
            :rules="rules.requiredFunction('extensaoCliente', 'descricao')"
            :label="`${textoFocoAcao} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="descricao"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaFocoAcao || desabilitarExtensaoCliente"
            :placeholder="somenteLeitura || desabilitaFocoAcao || desabilitarExtensaoCliente ?
              null : $tc('message.digite_para_pesquisar', 1)"
            :clearable="true"
            @input="extensaoClienteAlterada"
            @click:append="() => triggerSelecao('planejamento-autocomplete-extensao-cliente')"
            @click.native="(i) => buscaAutocomplete(i, buscaExtensaoCliente)"
            :search-input.sync="triggerExtensoesCliente">
          </v-autocomplete>

        </v-col>
        <v-col cols="12" class="py-0"
               v-if="exibirPagamento && focoAcao">
          <v-select
            id="planejamento-select-foco-pagamento"
            :items="focoPagamentos"
            item-text="texto"
            item-value="valor"
            :rules="[rules.required]"
            v-model="focoPagamento"
            :disabled="desabilitarPagamento"
            :label="`${$tc('label.pagamento', 1)} *`"
            @change="focoPagamentoAlterado">
          </v-select>
        </v-col>
        <v-col cols="12" class="pt-0"
          v-if="exibirPagamento && focoAcao">
          <v-autocomplete
            id="planejamento-autocomplete-clientepagador"
            class="custom-autocomplete"
            v-if="exibeClientePagador"
            v-model="acao.clientePagador"
            return-object
            :rules="rules.requiredFunction('clientePagador', 'codNome')"
            :items="clientesPagadores"
            :label="`${$tc('label.cliente_pagador', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="codNome"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaClientePagador"
            :placeholder="(somenteLeitura || desabilitaClientePagador) ? null : $tc('message.digite_para_pesquisar', 1)"
            :clearable="true"
            @click:append="() => triggerSelecao('planejamento-autocomplete-clientepagador')"
            @click.native="(i) => buscaAutocomplete(i, buscaClientesPagadores)"
            :search-input.sync="triggerClientePagador">
          </v-autocomplete>
          <v-autocomplete
            id="planejamento-autocomplete-fornecedorpagador"
            class="custom-autocomplete"
            v-if="exibeFornecedorPagador"
            v-model="acao.fornecedorPagador"
            return-object
            :rules="rules.requiredFunction('fornecedorPagador', 'codNomeCnpj')"
            :items="fornecedoresPagadores"
            :label="`${this.$tc('label.fornecedor_pagador', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="codNomeCnpj"
            item-value="id"
            :disabled="somenteLeitura || desabilitaFornecedorPagador"
            :placeholder="(somenteLeitura || desabilitaFornecedorPagador) ? null : $tc('message.digite_para_pesquisar', 1)"
            :clearable="true"
            @click:append="() => triggerSelecao('planejamento-autocomplete-fornecedorpagador')"
            @click.native="(i) => buscaAutocomplete(i, buscaFornecedoresPagadores)"
            :search-input.sync="triggerFornecedorPagador">
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { buscaAutocomplete, triggerSelecao } from '../../../common/functions/autocomplete-utils';
import {
  buscarHoldings, buscarExtensaoCliente, buscarClientes, buscarRegionais,
} from '../../../common/resources/planejamento/planejamento-acao-cadastro';

export default {
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acao: Object,
    acaoEdicao: {
      type: Object,
      default: () => {},
    },
    configuracao: {
      type: Object,
      required: true,
    },
    tipoAcao: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isEdicao() {
      return !this.novaAcao;
    },
    desabilitaFormDivisao() {
      const { divisao } = this.acao;
      return !divisao || !divisao.id;
    },
    desabilitaFocoAcao() {
      const { unidadeNegocio } = this.acao;
      return !unidadeNegocio || !unidadeNegocio.id;
    },
    focoAcaoConfiguracao() {
      const { focoAcao } = this.configuracao;
      focoAcao.forEach((fa) => {
        if (!fa.texto) {
          fa.texto = this.$tc(fa.i18n, 1);
        }
      });
      return focoAcao;
    },
    focoPagamentos() {
      const itensPagamento = [];

      if (this.configuracao.habilitaClientePagador
        && (this.exibeFocoHolding || this.exibeFocoCliente || this.exibeFocoRegional)) {
        itensPagamento.push({
          texto: this.$tc('label.cliente', 1),
          valor: 'CLIENTE',
        });
      }

      if (this.configuracao.habilitaFornecedorPagador) {
        itensPagamento.push({
          texto: this.$tc('label.fornecedor', 1),
          valor: 'FORNECEDOR',
        });
      }

      if (itensPagamento.length === 1) {
        this.focoPagamento = itensPagamento[0].valor;
      }
      return itensPagamento;
    },
    exibeFocoHolding() {
      return this.focoAcao === 'HOLDING';
    },
    exibeFocoCliente() {
      return this.focoAcao === 'CLIENTE';
    },
    exibeFocoRegional() {
      return this.focoAcao === 'REGIONAL';
    },
    textoFocoAcao() {
      const foco = this.focoAcaoConfiguracao.find((f) => f.valor === this.focoAcao);
      return foco ? foco.texto : '';
    },
    exibeClientePagador() {
      return this.configuracao.habilitaClientePagador && this.focoPagamento === 'CLIENTE';
    },
    exibeFornecedorPagador() {
      return this.configuracao.habilitaFornecedorPagador && this.focoPagamento === 'FORNECEDOR';
    },
    desabilitarPagamento() {
      return this.somenteLeitura || this.focoPagamentos.length < 2;
    },
    exibirPagamento() {
      return this.configuracao.habilitaClientePagador
        || this.configuracao.habilitaFornecedorPagador;
    },
    unicoFocoAcao() {
      const size = this.focoAcaoConfiguracao.length === 1;
      if (size) {
        this.focoAcao = this.focoAcaoConfiguracao[0].valor;
      }
      return size;
    },
    desabilitaClientePagador() {
      const { extensaoCliente, holding } = this.acao;
      return !((extensaoCliente && extensaoCliente.id) || (holding && holding.id));
    },
    desabilitaFornecedorPagador() {
      const {
        extensaoCliente, holding, cliente, regional,
      } = this.acao;
      return !((extensaoCliente && extensaoCliente.id)
        || (holding && holding.id)
        || (cliente && cliente.id)
        || (regional && regional.id));
    },
  },
  watch: {
    focoPagamentos(val) {
      if (val.length === 1) {
        this.focoPagamento = val[0].valor;
      }
    },
    triggerHolding(val) {
      const { holding } = this.acao;
      if (holding && holding.nomExtensao === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaHoldings(val);
      }, 500);
    },
    triggerExtensoesCliente(val) {
      const { extensaoCliente } = this.acao;
      if (extensaoCliente && extensaoCliente.nomExtensao === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaExtensaoCliente(val);
      }, 500);
    },
    triggerCliente(val) {
      const { cliente } = this.acao;
      if (cliente && cliente.codNome === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaClientes(val);
      }, 500);
    },
    triggerRegional(val) {
      const { regional } = this.acao;
      if (regional && regional.codNome === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaRegionais(val);
      }, 500);
    },
    triggerClientePagador(val) {
      const { clientePagador } = this.acao;
      if (clientePagador && clientePagador.codNome === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaClientesPagadores(val);
      }, 500);
    },
    triggerFornecedorPagador(val) {
      const { fornecedorPagador } = this.acao;
      if (fornecedorPagador && fornecedorPagador.codNomeCnpj === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscaFornecedoresPagadores(val);
      }, 500);
    },
  },
  data() {
    return {
      clienteResource: this.$api.cliente(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      usuarioLogadoResource: this.$api.usuarioLogado(this.$resource),

      holdings: [],
      extensoesCliente: [],
      clientes: [],
      clientesPagadores: [],
      fornecedoresPagadores: [],
      divisoes: [],
      regionais: [],
      regionaisFiltradas: [],

      triggerHolding: null,
      triggerExtensoesCliente: null,
      triggerCliente: null,
      triggerClientePagador: null,
      triggerFornecedorPagador: null,
      triggerRegional: null,
      timeoutTrigger: null,
      desabilitarExtensaoCliente: false,

      focoAcao: null,
      focoPagamento: null,

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFunctionOpcional: (objLabel, valueLabel, obrigatorio) => [
          () => {
            if (!obrigatorio) {
              return true;
            }

            const objeto = this.acao[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
        requiredFunction: (objLabel, valueLabel) => [
          () => {
            const objeto = this.acao[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
      },
      pararEsperar: false,
    };
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    alterarTipo() {
      const { focoAcao } = this.configuracao;
      this.acao.holding = null;
      this.acao.regional = null;
      this.acao.extensaoCliente = null;
      this.acao.cliente = null;
      const foco = focoAcao.find((fa) => fa.valor === this.focoAcao);
      if (foco) {
        this.acao.focoAcao = foco.texto;
      }
      this.preencherExtensaoCliente();
    },
    preencherExtensaoCliente() {
      const extCarteira = this.configuracao.extensoesCarteira
        .find((c) => c.mnemonico === this.focoAcao);
      if (extCarteira) {
        this.acao.extensaoCliente = this.acao[extCarteira.label];
        this.extensoesCliente = [this.acao.extensaoCliente];
        this.desabilitarExtensaoCliente = true;
      } else {
        this.desabilitarExtensaoCliente = false;
      }
    },
    focoPagamentoAlterado() {
      if (this.exibeFocoCliente && this.focoPagamento === 'CLIENTE') {
        this.clienteAlterado(this.acao.cliente);
      } else {
        this.acao.clientePagador = null;
      }
      this.acao.fornecedorPagador = null;
      this.acao.focoPagamento = this.focoPagamento;
    },
    divisaoAlterada() {
      if (this.acao.unidadeNegocio) {
        this.acao.unidadeNegocio = {};
      }
      if (this.acao.divisao) {
        this.acao.divisao.codNome = this.acao.divisao.id;
        this.buscarUnidadesNegocioDivisao();
      }
    },
    regionalAlterada(regional) {
      if (regional) {
        this.acao.regional = { ...regional };
      }
      const { clientePagador, fornecedorPagador } = this.acao;
      if (clientePagador && clientePagador.id) {
        this.acao.clientePagador = {};
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        this.acao.fornecedorPagador = {};
      }
      this.$emit('PlanejamentoAcaoFormFocoAcao__alteradoFoco', this.exibeFocoRegional);
    },
    buscarDivisoesUsuario() {
      this.planejamentoAcaoResource.buscarDivisoes()
        .then((res) => {
          this.divisoes = res.data;
          this.divisoesFiltradas = res.data;
          if (this.divisoes.length === 1) {
            const unicaDivisao = this.divisoes[0];
            this.acao.divisao = { ...unicaDivisao };
            this.buscarUnidadesNegocioDivisao();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarUnidadesNegocioDivisao(autocomplete) {
      const idDivisao = this.acao.divisao.id;
      const parametros = { idDivisao, autocomplete };
      return this.planejamentoAcaoResource
        .buscarUnidadesNegocioDivisao(parametros)
        .then((res) => {
          this.unidadesNegocioDivisao = res.data;
          if (this.unidadesNegocioDivisao.length === 1) {
            const unicaUnidade = this.unidadesNegocioDivisao[0];
            this.acao.unidadeNegocio = { ...unicaUnidade };
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaHoldings(autocomplete) {
      const parametros = {
        autocomplete,
        id_unidadenegocio: [
          this.acao.unidadeNegocio.id,
        ],
        idPeriodoPlanejamento: this.acao.periodoPlanejamento
          ? this.acao.periodoPlanejamento.id
          : null,
      };
      if (this.acao.divisao && this.acao.divisao.id) {
        parametros.idDivisao = this.acao.divisao.id;
      }
      parametros.idExtensoesCarteira = this.recuperarExtensoesCarteiras();
      buscarHoldings(parametros, this.$resource)
        .then((res) => {
          this.holdings = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaExtensaoCliente(autocomplete) {
      const parametros = {
        autocomplete,
        id_unidadenegocio: [
          this.acao.unidadeNegocio.id,
        ],
        mnemonico: this.focoAcao,
      };
      if (this.acao.divisao && this.acao.divisao.id) {
        parametros.idDivisao = this.acao.divisao.id;
      }
      parametros.idExtensoesCarteira = this.recuperarExtensoesCarteiras();
      buscarExtensaoCliente(parametros, this.$resource)
        .then((res) => {
          this.extensoesCliente = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    recuperarExtensoesCarteiras() {
      const idsExtensoesCarteira = this.configuracao.extensoesCarteira
        .map((e) => this.acao[e.label])
        .filter((e) => !!e)
        .map((e) => e.id);
      if (this.configuracao.unidadeNegocioCarteira && this.acao.unidadeNegocio
        && this.acao.unidadeNegocio.id) {
        idsExtensoesCarteira.push(this.acao.unidadeNegocio.id);
      }
      return idsExtensoesCarteira;
    },
    buscaRegionais(autocomplete) {
      const parametros = {
        autocomplete,
        idDivisao: this.acao.divisao.id,
        id_unidadenegocio: [
          this.acao.unidadeNegocio.id,
        ],
      };
      parametros.idExtensoesCarteira = this.recuperarExtensoesCarteiras();
      buscarRegionais(parametros, this.$resource)
        .then((res) => {
          this.regionais = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    getAcao() {
      return this.acao;
    },
    buscaClientes(autocomplete) {
      const parametros = {
        autocomplete,
        idUnidadeNegocio: this.acao.unidadeNegocio.id,
      };
      if (this.acao.divisao && this.acao.divisao.id) {
        parametros.idDivisao = this.acao.divisao.id;
      }
      parametros.idExtensoesCarteira = this.recuperarExtensoesCarteiras();
      buscarClientes(parametros, this.$resource)
        .then((res) => {
          this.clientes = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaClientesPagadores(autocomplete) {
      const parametros = this.criarParametros(autocomplete);
      this.planejamentoAcaoResource.buscarClientesPagadores(parametros)
        .then((res) => {
          this.clientesPagadores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaFornecedoresPagadores(autocomplete) {
      const parametros = this.criarParametros(autocomplete);
      parametros.pagador = false;
      this.planejamentoAcaoResource.buscarFornecedoresPagadores(parametros)
        .then((res) => {
          this.fornecedoresPagadores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    criarParametros(autocomplete) {
      const parametros = {
        autocomplete,
      };
      if (this.acao.holding && this.acao.holding.id) {
        parametros.idHolding = this.acao.holding.id;
      }
      if (this.acao.unidadeNegocio && this.acao.unidadeNegocio.id
        && !this.exibeFocoRegional) {
        parametros.idUnidadeNegocio = this.acao.unidadeNegocio.id;
      }
      if (this.acao.extensaoCliente && this.acao.extensaoCliente.id) {
        parametros.idExtensaoCliente = this.acao.extensaoCliente.id;
      }
      return parametros;
    },
    limpaFocoAcao() {
      const {
        holding, cliente, regional,
        clientePagador, extensaoCliente, fornecedorPagador,
      } = this.acao;
      if (holding && holding.id) {
        this.acao.holding = {};
      }
      if (cliente && cliente.id) {
        this.acao.cliente = {};
      }
      if (regional && regional.id) {
        this.acao.regional = {};
      }
      if (clientePagador && clientePagador.id) {
        this.acao.clientePagador = {};
      }
      if (extensaoCliente && extensaoCliente.id) {
        this.acao.extensaoCliente = {};
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        this.acao.fornecedorPagador = {};
      }
      this.preencherExtensaoCliente();
    },
    holdingAlterada() {
      const { clientePagador, fornecedorPagador } = this.acao;
      if (clientePagador && clientePagador.id) {
        this.acao.clientePagador = {};
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        this.acao.fornecedorPagador = {};
      }
      this.$emit('PlanejamentoAcaoFormFocoAcao__alteradoFoco');
      this.buscarContasCorrentes();
    },
    extensaoClienteAlterada() {
      const { clientePagador, fornecedorPagador } = this.acao;
      if (clientePagador && clientePagador.id) {
        this.acao.clientePagador = {};
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        this.acao.fornecedorPagador = {};
      }
      this.$emit('PlanejamentoAcaoFormFocoAcao__alteradoFoco');
    },
    clienteAlterado(cliente) {
      this.acao.clientePagador = cliente ? { ...cliente } : null;
      this.clientesPagadores = [
        this.acao.clientePagador,
      ];
      this.$emit('PlanejamentoAcaoFormFocoAcao__alteradoFoco');
      this.buscarContasCorrentes();
    },
    trataFocoAcao() {
      if (this.unicoFocoAcao) {
        this.focoAcao = this.focoAcaoConfiguracao[0].valor;
      }
    },
    getTextoFoco() {
      const { focoAcao } = this.configuracao;
      const foco = focoAcao.find((fa) => fa.valor === this.focoAcao);
      if (!foco) {
        return '';
      }
      return foco.texto;
    },
    setAcaoEdicao() {
      const {
        cliente,
        holding,
        regional,
        clientePagador,
        extensaoCliente,
        fornecedorPagador,
      } = this.acao;

      if (holding) {
        this.focoAcao = 'HOLDING';
        this.holdings = [holding];
      } else if (regional) {
        this.focoAcao = 'REGIONAL';
        this.regionais = [regional];
      } else if (extensaoCliente) {
        this.focoAcao = extensaoCliente.mnemonico;
        this.extensoesCliente = [extensaoCliente];
        const extCarteira = this.configuracao.extensoesCarteira
          .find((c) => c.mnemonico === this.focoAcao);
        if (extCarteira) {
          this.desabilitarExtensaoCliente = true;
        }
      } else if (cliente) {
        this.focoAcao = 'CLIENTE';
        this.clientes = [cliente];
      }

      if (fornecedorPagador) {
        this.fornecedoresPagadores = [fornecedorPagador];
        this.focoPagamento = 'FORNECEDOR';
      }
      if (clientePagador) {
        this.clientesPagadores = [clientePagador];
        this.focoPagamento = 'CLIENTE';
      }
    },
    buscarContasCorrentes() {
      this.$emit('PlanejamentoAcaoForm__contasCorrentes');
    },
  },
  mounted() {
    this.trataFocoAcao();
  },
};
</script>
