<template>
  <div class="PlanejamentoAcaoForm_Rodape">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="12" lg="6" class="pa-0">
          <v-stepper v-model="stepForm">
            <v-stepper-header>
              <div v-for="(passo, index) in sequenciaPassos"
                :key="index"
                :style="{'width': `${100/sequenciaPassos.length}%`}"
                class="PlanejamentoAcaoForm_Rodape__Passo">
                <v-stepper-step
                  :complete="stepForm > (index + 1)"
                  :step="(index+1)">
                  {{passo}}
                </v-stepper-step>
                <v-divider
                  v-if="index < sequenciaPassos.length - 1"/>
              </div>
            </v-stepper-header>
          </v-stepper>
        </v-col>

        <planejamento-acao-form-acoes-fluxo
          ref="componenteFluxo"
          :configuracao="configuracao"
          :acao-concluir="concluirAcao"
          :valida-concluir-acao="validaConcluirAcao"
          :ultimo-passo="ultimoPasso"
          :permite-salvar="permiteSalvar"
          :acao-edicao="acaoEdicao"
          :acao-salvar="salvar"
          :upload-evidencia-obrigatorio="uploadEvidenciaObrigatorio"
          :ind-tem-evidencia="indTemEvidencia"
          :edicao="edicao">
          <v-btn slot="acoes-padrao" @click="cancelar(true)" class="mr-3">
            {{ $t('label.voltar') }}
          </v-btn>
          <v-btn slot="acoes-padrao" @click="anterior()"
            class="mr-3"
            dark
            color="accent"
            v-show="exibeAnterior">
            {{ $t('label.anterior') }}
          </v-btn>
          <v-btn slot="acoes-padrao" @click="proximo()"
            class="mr-3"
            dark
            color="primary"
            v-show="exibeProximo">
            {{ $tc('label.proximo', 1) }}
          </v-btn>
          <v-btn slot="acoes-padrao" @click="salvarRecarregar()"
            class="mr-3"
            dark
            color="primary"
            v-show="exibeSalvar"
            :disabled="!permiteSalvar">
            {{ $t('label.salvar') }}
          </v-btn>
        </planejamento-acao-form-acoes-fluxo>
      </v-row>
    </v-container>
    <confirm
      ref="cancelamentoDialog"
      :persistent="true">
    </confirm>
  </div>
</template>
<script>
import PlanejamentoAcaoFormAcoesFluxo from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormAcoesFluxo';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import AcaoComponenteEvento from './passos-dinamicos/AcaoComponenteEvento';

export default {
  props: {
    definicaoPassos: Object,

    edicao: Boolean,
    somenteLeitura: Boolean,
    acaoEdicao: Object,

    getComponentesPassos: Function,

    callbackAcao: {
      type: Function,
      default: null,
    },

    validaComponentePasso: Function,
    validaComponentePassoSalvar: Function,

    indTemEvidencia: Boolean,
    usuarioCliente: Boolean,
    usuarioClientePodeAcessarPassoContas: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  components: {
    Confirm,
    PlanejamentoAcaoFormAcoesFluxo,
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      stepForm: 1,
      avancaPasso: () => {},
    };
  },
  computed: {
    permiteSalvar() {
      return (
        !this.acaoEdicao.status
        || this.acaoEdicao.status === 'EM_CADASTRO'
      );
    },
    ultimoPasso() {
      const ultimoPasso = Object.keys(this.sequenciaPassos).length;
      return this.stepForm === ultimoPasso;
    },
    exibeAnterior() {
      return this.stepForm !== 1;
    },
    exibeSalvar() {
      if (this.somenteLeitura) return false;
      return this.ultimoPasso;
    },
    exibeProximo() {
      return !this.ultimoPasso;
    },
    sequenciaPassos() {
      return Object.values(this.passosHabilitados)
        .filter((passo) => passo.habilitado)
        .map((passo) => passo.label);
    },
    passosHabilitados() {
      return this.definicaoPassos.passosHabilitados(this, this.configuracao.configuracao, this.acaoEdicao);
    },
    uploadEvidenciaObrigatorio() {
      return this.configuracao
        && this.configuracao.configuracao
        && this.configuracao.configuracao.passo1
        && this.configuracao.configuracao.passo1.uploadObrigatorio;
    },
  },
  methods: {
    carregaInformacoesFluxo(idAcao) {
      this.$refs.componenteFluxo.carregaInformacoesFluxo(idAcao);
    },
    validaAntesDeAvancar() {
      return this.validaComponentePasso();
    },
    abreUltimoPasso() {
      const ultimoPasso = Object.keys(this.sequenciaPassos).length;
      const evento = new AcaoComponenteEvento(
        'antesIrUltimoPasso',
        {
          proximo: ultimoPasso,
          anterior: this.stepForm,
        },
        () => {
          this.stepForm = ultimoPasso;
          this.notificaNovoPasso();
        },
      );
      this.$emit('PlanejamentoAcaoForm__executarEventoEncadeado', evento);
    },
    carregarEdicao() {
      const evento = new AcaoComponenteEvento(
        'carregarEdicao',
      );
      this.$emit('PlanejamentoAcaoForm__executarEventoEncadeado', evento);
    },
    proximo() {
      if (!this.validaAntesDeAvancar()) {
        return;
      }
      const evento = new AcaoComponenteEvento(
        'antesIrProximo',
        {
          proximo: this.stepForm + 1,
          anterior: this.stepForm,
        },
        () => {
          this.stepForm += 1;
          this.notificaNovoPasso();
        },
      );
      this.$emit('PlanejamentoAcaoForm__antesIrProximo', evento);
    },
    anterior() {
      this.stepForm -= 1;
      this.notificaNovoPasso();
    },
    notificaNovoPasso() {
      this.$emit('PlanejamentoAcaoFormRodape__MudaPasso', this.stepForm);
    },
    cancelar(forceVoltaParaLista = false) {
      if (this.somenteLeitura) {
        this.$router.push({
          name: this.$route.params.from || 'planejamento-acao',
        });
        return;
      }

      this.$refs.cancelamentoDialog
        .openWithParams(
          this.$t('title.atencao'),
          this.$t('message.deseja_cancelar_edicao'),
          (ok) => {
            if (ok) {
              if (forceVoltaParaLista) {
                this.$router.push({
                  name: 'planejamento-acao',
                });
              } else {
                this.$router.push({
                  name: this.$route.params.from || 'planejamento-acao',
                });
              }
            }
          },
        );
    },
    validaAntesDeSalvar(isSolicitandoAprovacao) {
      return this.validaComponentePassoSalvar(isSolicitandoAprovacao);
    },
    salvarRecarregar() {
      this.salvar()
        .then((idAcao) => {
          if (this.callbackAcao) {
            this.callbackAcao(idAcao);
          }
          this.alternaRota(idAcao);
        })
        .catch((err) => {
          if (err) {
            this.$toast(err.data.error);
          }
        });
    },
    salvar(isSolicitandoAprovacao = false) {
      if (!this.validaAntesDeSalvar(isSolicitandoAprovacao)) {
        return Promise.reject();
      }

      const acao = this.formataAcaoParaEnvio();

      if (!this.edicao) {
        return this.salvarNovo(acao);
      }
      return this.atualizar(acao);
    },
    formataAcaoParaEnvio() {
      const componentes = this.getComponentesPassos();
      const acao = {
        id: this.acaoEdicao.id,
      };

      Object.keys(componentes).forEach((k) => {
        const componente = componentes[k];
        if (componente.preencherObjetoFormatado) {
          componente.preencherObjetoFormatado(acao);
        }
      });
      return acao;
    },
    salvarNovo(acao) {
      return this.planejamentoAcaoResource.salvarAcao(acao)
        .then((res) => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          const idAcao = res.data;
          return idAcao;
        });
    },
    atualizar(acao) {
      return this.planejamentoAcaoResource.atualizarAcao(acao)
        .then((res) => {
          this.$toast(this.$t('message.acao_atualizada'));
          const idAcao = res.data;
          return idAcao;
        });
    },
    alternaRota(idAcao) {
      const routeEdicao = 'editarAcao';

      let { name } = this.$router.currentRoute;
      name = name === routeEdicao
        ? name.concat('_') : routeEdicao;

      this.$router.replace({
        name,
        params: { idAcao, passoFinal: true, from: 'detalharAcao' },
      });
    },
    validaConcluirAcao() {
      if (!this.validaAntesDeSalvar(true)) {
        return false;
      }
      return true;
    },
    concluirAcao() {
      const acao = this.formataAcaoParaEnvio();
      return this.planejamentoAcaoResource.concluirAcao(acao)
        .then((res) => this.alternaRota(res.data))
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    this.$root.$on('voltar', () => {
      this.anterior();
    });

    const { passoFinal } = this.$route.params;
    if (passoFinal === true) {
      this.abreUltimoPasso();
    } else if (this.edicao) {
      this.carregarEdicao();
    }
  },
};
</script>
<style>
  .PlanejamentoAcaoForm_Rodape {
    height: auto !important;
    background-color: #EEEEEE;
    display: flex;
    position: fixed;
    z-index: 4;
    transform: translate(0);
    transition: all .4s cubic-bezier(.25,.8,.5,1);
    bottom: 0px;
    left: 0;
    width: 100%;
  }
  .PlanejamentoAcaoForm_Rodape .v-stepper {
    background: none;
    border: none;
    box-shadow: none;
  }
  .PlanejamentoAcaoForm_Rodape .v-stepper__header,
  .PlanejamentoAcaoForm_Rodape .v-stepper__step {
    height: 56px;
  }
  .PlanejamentoAcaoForm_Rodape__Passo {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
</style>
