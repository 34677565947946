import PlanejamentoAcaoFormCampos from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormCampos';
import PlanejamentoAcaoFormComportamento from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormComportamento';
import PlanejamentoAcaoFormContaCorrenteWrapper from './PlanejamentoAcaoFormContaCorrenteWrapper';

const habilitaPassoContas = (configuracao, ref) => {
  const { passo1 } = configuracao;
  if (ref.usuarioCliente && !ref.usuarioClientePodeAcessarPassoContas) {
    return false;
  }
  return passo1.investimentos.filter((i) => i.operacao === 'DEBITO').length > 0;
};

const habilitaPassoComportamento = (configuracao) => {
  const { passo1, passo5 } = configuracao;
  return passo1.fluxo.indexOf('APURACAO') >= 0 && !!passo5;
};

export default {
  componentes: {
    PlanejamentoAcaoFormCampos,
    PlanejamentoAcaoFormComportamento,
    PlanejamentoAcaoFormContaCorrenteWrapper,
  },
  passosHabilitados(ref, configuracao, acao) { // eslint-disable-line
    return {
      geral: {
        habilitado: true,
        label: ref.$tc('label.geral', 1),
        componente: 'planejamento-acao-form-campos',
      },
      comportamento: {
        habilitado: habilitaPassoComportamento(configuracao),
        label: ref.$tc('label.apuracao', 1),
        componente: 'planejamento-acao-form-comportamento',
      },
      contas: {
        habilitado: habilitaPassoContas(configuracao, ref),
        label: ref.$tc('label.conta_corrente', 1),
        componente: 'planejamento-acao-form-conta-corrente-wrapper',
      },
    };
  },
};
