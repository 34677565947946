import { buscaAutocomplete, triggerSelecao } from '../../../common/functions/autocomplete-utils';
import { buscarProdutosSelecao } from '../../../common/resources/produto';

export default {
  watch: {
    triggerDivisoes(val) {
      const { divisao } = this.acao;
      if (divisao && divisao.nomExtensao === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscaDivisoes(val);
      }, 500);
    },
  },
  computed: {
    isEdicao() {
      return !this.novaAcao;
    },
    isCliente() {
      return this.acao.tipoAcaoTatica === 'CLIENTE';
    },
    appendIcon() {
      return !this.somenteLeitura ? 'arrow_drop_down' : '';
    },
    desabilitaDivisao() {
      return !this.novaAcao || this.divisoes.length <= 1;
    },
    desabilitaUnidadeNegocio() {
      const { divisao } = this.acao;
      if (!divisao || !divisao.id || this.unidadesNegocioDivisao.length <= 1) {
        return true;
      }
      return false;
    },
    desabilitaExtensaoCarteira() {
      const { divisao } = this.acao;
      return !divisao || !divisao.id;
    },
  },
  data() {
    return {
      anoFiscalResource: this.$api.anoFiscal(this.$resource),
      centroCustoResource: this.$api.centroCusto(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      tipoBeneficioResource: this.$api.tipoBeneficio(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),
      anoFiscalResources: this.$api.anoFiscal(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      usuarioLogadoResource: this.$api.usuarioLogado(this.$resource),
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'status',
            tipoCampo: 'TEXTO',
            vlrObrigatorio: true,
            desAtributos: {
              desabilitado: true,
            },
          },
          {
            labelCampo: 'valor',
            tipoCampo: 'DINHEIRO',
            vlrObrigatorio: true,
            desAtributos: {
              regrasValidacao: [(value) => value <= 99999999.99 || this.$t('message.valor_padrao_maximo')],
            },
          },
          {
            labelCampo: 'produto',
            nomCampoId: 'idProduto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresDependencias(),
                };
                return buscarProdutosSelecao(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codigoNome',
            },
          },
        ],
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFunctionOpcional: (objLabel, valueLabel, obrigatorio) => [
          () => {
            if (!obrigatorio) {
              return true;
            }

            const objeto = this.acao[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
        requiredFunction: (objLabel, valueLabel) => [
          () => {
            const objeto = this.acao[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
      },
      timeoutTrigger: null,
      listasExtensoesCarteira: {},

      acao: {
        status: this.$t('status_entidade.em_cadastro'),
        divisao: null,
        unidadeNegocio: null,
        holding: null,
        regional: null,
        extensaoCliente: null,
        focoPagamento: null,
        cliente: null,
        clientePagador: null,
        grupoFornecedor: null,
        fornecedor: null,
        fornecedorPagador: {},
        focoAcao: null,
        valor: 0,
      },

      divisoesFiltradas: [],
      divisoes: [],
      unidadesNegocioDivisao: [],

      parametrosAplicados: false,
      metadadosParametrizado: null,

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm6: true,
        md3: true,
      },
      definicaoDtaInicio: {
        labelCampo: 'data_inicio',
        nomCampoId: 'dtaInicio',
        tipoCampo: 'DATA',
        vlrObrigatorio: true,
      },
      definicaoDtaFim: {
        labelCampo: 'data_fim',
        nomCampoId: 'dtaFim',
        tipoCampo: 'DATA',
        vlrObrigatorio: true,
      },
    };
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    buscarDivisoes(autocomplete) {
      this.divisoesFiltradas = this.divisoes
        .filter((divisao) => divisao.nome.indexOf(autocomplete) >= 0);
    },
    buscarDivisoesUsuario() {
      this.planejamentoAcaoResource.buscarDivisoes()
        .then((res) => {
          this.divisoes = res.data;
          this.divisoesFiltradas = res.data;
          if (this.divisoes.length === 1) {
            const unicaDivisao = this.divisoes[0];
            this.acao.divisao = { ...unicaDivisao };
            this.buscarUnidadesNegocioDivisao();
            this.buscarExtensoesCarteira();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarExtensoesCarteira() {
      if (!this.configuracao.temExtensaoCarteira) return;
      this.listasExtensoesCarteira = {};
      this.configuracao.extensoesCarteira
        .forEach((e) => this.buscarExtensaoCarteira(e.mnemonico, e.label));
    },
    buscarExtensaoCarteira(mnemonico, label) {
      if (this.acao[label] && this.acao[label].id) {
        this.acao[label] = {};
      }
      const idDivisao = this.acao.divisao.id;
      const parametros = { idDivisao, mnemonico };
      return this.planejamentoAcaoResource
        .buscarExtensaoDivisao(parametros)
        .then((res) => {
          this.listasExtensoesCarteira[label] = res.data;
          if (this.listasExtensoesCarteira[label].length === 1) {
            const extensao = this.listasExtensoesCarteira[label][0];
            this.acao[label] = { ...extensao };
          }
          this.$forceUpdate();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarUnidadesNegocioDivisao(autocomplete) {
      const idDivisao = this.acao.divisao.id;
      const parametros = { idDivisao, autocomplete };
      return this.planejamentoAcaoResource
        .buscarUnidadesNegocioDivisao(parametros)
        .then((res) => {
          this.unidadesNegocioDivisao = res.data;
          if (this.unidadesNegocioDivisao.length === 1) {
            const unicaUnidade = this.unidadesNegocioDivisao[0];
            this.acao.unidadeNegocio = { ...unicaUnidade };
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaDivisoes(autocomplete) {
      const parametros = {
        autocomplete,
        idUnidadeNegocio: this.acao.unidadeNegocio.id,
      };
      this.divisaoResource.hierarquia(parametros)
        .then((res) => {
          this.divisoes = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    divisaoAlterada() {
      if (this.acao.unidadeNegocio) {
        this.acao.unidadeNegocio = {};
      }
      if (this.acao.divisao) {
        this.acao.divisao.codNome = this.acao.divisao.id;
        this.buscarUnidadesNegocioDivisao();
        this.buscarExtensoesCarteira();
      }
      if (this.$refs.formFocoAcao) {
        this.$refs.formFocoAcao.limpaFocoAcao();
      }
    },
    holdingAlterada() {
      const { clientePagador } = this.acao;
      if (clientePagador && clientePagador.id) {
        this.acao.clientePagador = {};
      }
    },
    divisaoFormAlterada(divisao) {
      this.acao.divisaoAcao = divisao ? { ...divisao } : null;
      this.clientesPagadores = [
        this.acao.divisaoAcao,
      ];
    },
  },
};
